<template>
  <div>
    <div class="main" v-title data-title="百川资管-基金产品">
    </div>
    <Header class="header" active="product" />
    <div class="banner">
      <div class="background">
        <b-container>
          <div class="title">基于人工智能的基本面量化基金，以专业创造<br />价值，为客户提供可信赖的资产管理服务。</div>
          <div class="text">
            <img src="../assets/qrcode.png" class="qrcode">
            <div class="qr-desc">
              <div class="sub-title">微信关注『百川资管』</div>
              <div class="sub-title">了解公司信息及产品信息</div>
            </div>
            <div></div>
            <div></div>
          </div>
        </b-container>
      </div>
    </div>
    <div class="content">
      <section class="product">
        <b-container>
          <div class="list">
            <div class="item">
              <span class="tag">基本面量化产品</span>
              <b-row>
                <div class="col-6">
                  <img src="../assets/product8.png" style="width:100%;margin-top:10px">
                </div>
                <div class="col-6">
                  <div>
                    <span class="title">投资标的：</span><span class="sub-title">A股/港股/美股(中概股)+对冲工具</span>
                  </div>
                  <div><span class="title">股市涨跌敏感度：</span><span class="sub-title" style="padding-left:45%;padding-right:15px;background-color:#4e7aaa;border-radius: 5px;padding-top:3px;padding-bottom:5px;color:#fff;margin-left:20px">中等</span></div>
                  <div><span class="title">产品收益波动性：</span><span class="sub-title" style="padding-left:45%;padding-right:15px;background-color:#4e7aaa;border-radius: 5px;padding-top:3px;padding-bottom:5px;color:#fff;margin-left:20px">中等</span></div>
                  <div><span class="title">同时持有股票数量</span><span class="sub-title">[10,50]</span></div>
                  <div class="title">稳健的复利投资者：</div>
                  <div style="padding-right:15px;padding-bottom:15px">全球权益资产配置，面对市场波动运用金融衍生品对冲管理股票头寸大幅波动风险，有效规避市场下跌风险，形成超越牛熊的投资模式。</div>
                </div>
              </b-row>
            </div>
          </div>
        </b-container>
      </section>
      <section class="idea">
        <b-container>
          <b-row>
            <div class="text">
              投资理念：拥抱高质量成长股
            </div>
          </b-row>
          <div class="wd86">
            <div class="col-6">
              <div class="background">
                <div class="title">我们的基本面研究</div>
                <div class="sub-title">体系化深入的研究企业的经营，以长期战略股东的角度判断企业的投资价值与企业共同成长</div>
              </div>
            </div>
            <div class="col-6">
              <div class="background">
                <div class="title">我们的量化研究</div>
                <div class="sub-title">客观呈现市场运行轨迹，敬畏市场，顺势而为，运用模型，但不迷信模型</div>
              </div>
            </div>
          </div>
          <div class="model">
            <img src="../assets/product5.png">
          </div>
        </b-container>
      </section>
      <section class="strategy">
        <b-container>
          <div class="text">
            核心策略：基本面精选+量化配置=双轮驱动
          </div>
          <div class="wd86">
            <div class="col-6">
              <div class="item">
                <img src="../assets/product7.png">
                <div class="title">基本面精选</div>
              </div>
            </div>
            <div class="col-6">
              <div>
                <img src="../assets/product6.png">
                <div class="title">量化配置</div>
              </div>
            </div>
          </div>
          <div class="wd86">
            <div class="col-6 br">
              <div class="item">
                <div class="sub-title">通过深入的基本面分析，精选业绩优秀、价值能长期成长的行业龙头公司，形成股票池，全球视角覆盖A股、港股、美股</div>
              </div>
            </div>
            <div class="col-6">
              <div>
                <div class="sub-title">基于自有的宏观、行业景气度等基本面量化配置体系，结合投资者行为、资金流动、动量趋势等市场面量化指标，灵活进行配置和风险管理</div>
              </div>
            </div>
          </div>
        </b-container>
      </section>
      <section class="purchase">
        <b-container>
          <b-row>
            <div class="text">如何申购百川产品</div>
          </b-row>
          <b-row>
            <div class="col-4">
              <div class="h1">1.</div>
            </div>
            <div class="col-4">
              <div class="h1">2.</div>
            </div>
            <div class="col-4">
              <div class="h1">3.</div>
            </div>
          </b-row>
          <b-row>
            <div class="col-4">
              <div class="h2">我能买吗？</div>
              <div class="h3">私募产品起投金额为100万元</div>
              <div class="h3">且满足以下合格投资者要求；</div>
              <div class="h3 mt15">个人</div>
              <div class="h4">金融资产≥300万元</div>
              <div class="h4">或 近三年年均收入≥50万元</div>
              <div class="h3 mt15">企业</div>
              <div class="h4">净资产≥1000万元</div>
            </div>
            <div class="col-4 blr">
              <div class="h2">选择产品</div>
              <div class="h3">产品业绩/策略介绍/费率条款</div>
              <div class="link">
                <a href="#">立即查看产品业绩</a>
              </div>
              <div class="h4">首次查看产品业绩需要填写风险评测，预计耗时3分钟</div>
            </div>
            <div class="col-4 npr">
              <div class="h2">签约购买</div>
              <div class="h3">联系百川，专人与您对接签约申购</div>
              <div class="h3">同时支持线上/现场签约</div>
              <div class="h3">联系方式</div>
              <div>
                <img src="../assets/qrcode.png" class="qr-code">
                <div class="phone-text">
                  <div class="h4">关注公众号/拨打电话</div>
                  <div class="h4"><img src="../assets/phone.png" class="phone">0755-23482434</div>
                </div>
              </div>
            </div>
          </b-row>
        </b-container>
      </section>
    </div>
    <Footer class="footer" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Product',
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
.banner {
  height: 550px;
}
.banner .background {
  width: 100%;
  height: 100%;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/product1.jpg");
}
.banner .title {
  font-size: 24px;
  color: #ffffff;
  line-height: 1.8;
}
.banner img {
  float: left;
}
.banner .text {
  position: relative;
  top: 30px;
}
.banner .sub-title {
  font-size: 18px;
  color: #ffffff;
  line-height: 2;
}
.banner .qr-desc {
  position: relative;
  left: 15px;
  top: 20px;
}
.banner .container {
  position: relative;
  top: 200px;
}

.product {
  background-color: #f2f2f2;
  padding: 10px 0 30px 0;
}

.product .item {
  box-shadow: 3px 3px 3px 3px #888888;
  border-radius: 5px;
  padding: 5px 0;
  background-color: #ffffff;
  position: relative;
  margin-top: 20px;
}
.product .title {
  font-size: 20px;
  line-height: 2.2;
}
.product .sub-title {
  padding-left: 20px;
}
.product .tag {
  background-color: #18466e;
  font-size: 18px;
  color: #ffffff;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px 20px;
}
.idea {
  padding-top: 50px;
}
.idea .text {
  color: #333333;
  font-size: 26px;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 60px;
}
.idea .background {
  width: 80%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/product2.png");
  padding: 10px;
  border-radius: 5px;
}
.idea .background .title {
  font-size: 18px;
  border-bottom: solid 1px #ffffff;
  color: #ffffff;
  text-align: center;
  padding-bottom: 5px;
}
.idea .background .sub-title {
  padding-top: 5px;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.8;
}
.wd86 {
  width: 86%;
  margin: 0 auto;
  display: flex;
}
.wd86 .br {
  border-right: solid 1px #333333;
}
.wd66 {
  width: 66%;
  margin: 0 auto;
}

.idea .model {
  padding: 50px 0;
  text-align: center;
}
.idea .model img {
  width: 72%;
  margin: 0 auto;
}

.strategy {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/product4.png");
  margin-top: -90px;
}
.strategy .container {
  position: relative;
  top: 150px;
  margin-bottom: 200px;
}
.strategy .title {
  padding: 20px 0;
  font-size: 18px;
  color: #0078d9;
}

.strategy .text {
  color: #333333;
  font-size: 26px;
  text-align: center;
  width: 100%;
  padding-bottom: 40px;
}

.strategy .col-6 {
  text-align: center;
  padding: 0 5%;
}
.strategy img {
  width: 120px;
}
.purchase {
  padding: 50px 0;
  background-color: #e7f6ff;
}
.purchase .text {
  color: #333333;
  font-size: 26px;
  text-align: center;
  width: 100%;
  padding-bottom: 40px;
}
.purchase .h1 {
  font-size: 40px;
  font-weight: bold;
}
.purchase .h2 {
  font-size: 20px;
  padding-bottom: 10px;
}
.purchase .h3 {
  font-size: 16px;
  color: #333333;
}
.purchase .h4 {
  font-size: 15px;
  color: gray;
  line-height: 1.8;
  margin-bottom: 0;
}
.purchase .link {
  padding: 30px 0;
}

.purchase a {
  border: solid 1px #333333;
  padding: 6px 40px;
  color: #333333;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 1px 1px 1px 1px #888888;
  text-decoration: none;
  position: relative;
  font-size: 14px;
}
.lh18 {
  line-height: 1.8;
}
.blr {
  border-left: solid 1px #333333;
  border-right: solid 1px #333333;
}
.purchase .col-4 {
  padding-left: 30px;
  padding-right: 60px;
}
.phone {
  width: 21px;
  margin-right: 10px;
}
.qrcode {
  width: 129px;
}
.qr-code {
  float: left;
  width: 129px;
}
.phone-text {
  position: relative;
  left: 10px;
  top: 30px;
}
.npr {
  padding-right: 0 !important;
}
.mt15 {
  margin-top: 15px;
}
</style>
